import axios from "axios";
import _ from "lodash";
import { useCallback, useState } from "react";
import { API_URL } from "./api";
import { Course } from "./types";

export function useCourseSearch() {
  const [searchTerm, setSearchTermRaw] = useState("");
  const [courses, setCourses] = useState<Course[]>([]);

  const onChangeSearchTerm = useCallback(
    _.debounce(function (newSearchTerm: string) {
      const params = new URLSearchParams({
        searchTerm: newSearchTerm,
      }).toString();
      return axios.get(`${API_URL}/course?${params}`).then((res) => {
        setCourses(res.data);
      });
    }, 200),
    [searchTerm],
  );

  const setSearchTerm = useCallback(
    function (value: string) {
      setSearchTermRaw(value);
      onChangeSearchTerm(value);
    },
    [onChangeSearchTerm],
  );
  function onBlur() {
    setCourses([]);
  }
  function onFocus() {
    onChangeSearchTerm(searchTerm);
  }

  return [setSearchTerm, courses, onBlur, onFocus] as const;
}
